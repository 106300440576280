@media (prefers-color-scheme: light) {
    body {
       background-color: var(--background-color-primary, #ffffff);
    }
}
@media (prefers-color-scheme: dark) {
    body {
        background-color: var(--background-color-primary, #010101);
    }
}

.light {
    --background-color-primary: #ffffff;
    --background-color-secondary: #efeff4;
    --background-content-background-tap: #f7f7f7;
    --background-general-bottomsheet: #f5f6f8;
    --background-order-passed: #8A8A8E;
    --button-secondary: #F7F7F7;
    --button-secondary-disabled: #f7f7f7;
    --button-secondary-active: #f7f7f7;
    --text-primary: #010101;
    --text-secondary: #28282a;
    --text-value: #8a8a8e;
    --text-attention: #FF9500;
    --text-note: #475569;
    --shevron-divider-secondary: #e0e0e5;
    --shevron-divider: #c8c8c8;
    --text-filed-default: #f7f7f7;
    --text-placeholder: #c8c8c8;
    --text-accent: #f0047f;
    --text-link: #007aff;
    --text-error: #ff3b30;
    --text-inner: #ffffff;
    --selection-control-toggle: #ffffff;
    --selection-control-toggle-active: #f0047f;
    --selection-control-background: #c8c8c8;
    --selection-control-segment-not-selected: #e0e0e5;
    --text-fileds-error: #fff5f4;
    --cells-simple: #ffffff;
    --cell-outline: #f7f7f7;
    --cell-other: #f7f7f7;
    --cell-other-tap: #ececec;
    --input-caret-cursor: #f0047f;
    --counter-color: #4a4a4a;
    --general-picker-divider: #C8C8C8;
}

.dark {
    --background-color-primary: #010101;
    --background-color-secondary: #28282A;
    --background-content-background-tap: #111112;
    --background-general-bottomsheet: #1C1C1E;
    --background-order-passed: #3D3D3D;
    --button-secondary: #4A4A4A;
    --button-secondary-disabled: #1c1c1e;
    --button-secondary-active: #4a4a4a;
    --text-primary: #ffffff;
    --text-secondary: #ececec;
    --text-value: #8a8a8e;
    --text-attention: #EB972F;
    --text-note: #c8c8c8;
    --shevron-divider-secondary: #28282a;
    --shevron-divider: #28282a;
    --text-filed-default: #28282a;
    --text-placeholder: #8a8a8e;
    --text-accent: #f0047f;
    --text-link: #007aff;
    --text-error: #d6232a;
    --text-inner: #ffffff;
    --selection-control-toggle: #ffffff;
    --selection-control-toggle-active: #f0047f;
    --selection-control-background: #4a4a4a;
    --selection-control-segment-not-selected: #28282a;
    --text-fileds-error: #1A0706;
    --cells-simple: #1c1c1e;
    --cell-outline: #f7f7f7;
    --cell-other: #28282a;
    --cell-other-tap: #4a4a4a;
    --input-caret-cursor: #f0047f;
    --counter-color: #ffffff;
    --general-picker-divider: #4A4A4A;
}
