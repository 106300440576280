@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/roboto-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/roboto-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/roboto-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'SFPro';
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/SFProDisplay-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'SFPro';
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/SFProDisplay-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'SFPro';
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/SFProDisplay-Bold.woff2') format('woff2');
}
