*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    border: none;
    background-color: transparent;
}

body {
    overflow: hidden;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color-primary);
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none; /* Стандартный синтаксис */
}

input,
select,
textarea {
    -webkit-tap-highlight-color: transparent;
}

img {
    pointer-events: none;
}
