.wrapper {
    position: fixed;
    width: 100%;
    height: 100dvh;
    background-color: var(--background-color-primary);
}

.logo {
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 2;
    transform: translateY(-50%);
}
