.android {
    &,
    & input,
    & button,
    & select {
        font-family: 'Roboto', system-ui, sans-serif;
    }
}

.ios {
    &,
    & input,
    & button,
    & select {
        font-family: 'SFPro', system-ui, sans-serif;
    }
}
