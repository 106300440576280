.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	white-space: nowrap;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
}

.h1 {
	font-size: 16px;
	line-height: 19px;
	font-weight: 500;
	color: var(--text-primary);
}

.h2 {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: var(--text-primary);
}

.common-text {
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
	color: var(--text-secondary);
}

.note {
	font-size: 12px;
	line-height: 14px;
	color: var(--gray-scale-black-2);
}

.capitalized {
	display: inline-block;
}

.capitalized:first-letter {
	text-transform: uppercase;
}

.container {
	padding: 16px;
}

.with-substrate-bottom-fixed {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 8px 16px 16px;
	background: var(--background-color-primary);
}

.bottom-fixed {
	position: fixed;
	right: 16px;
	bottom: 16px;
	left: 16px;
}

.top-fixed {
	position: fixed;
	left: 0;
	top: 0;
}

.fixed-button-container {
	padding-bottom: 80px;
}

.fixed-header-container {
	padding-top: 48px;
}

.pdf-container {
	position: relative;
}

.pdf-container .react-pdf__Page__textContent {
	user-select: text;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
}